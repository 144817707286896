import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCNy0kuks-fBw2kmDSpK3SOTR3exemFuh4",
  authDomain: "neuragest-news.firebaseapp.com",
  projectId: "neuragest-news",
  storageBucket: "neuragest-news.appspot.com",
  messagingSenderId: "1004247754954",
  appId: "1:1004247754954:web:88615d323e98af4444ec33"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);